import { Suspense } from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import ClassesListPage from '../../pages/dashboard/classes/list';
import ClassesDetailsPage from '../../pages/dashboard/classes/details';

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  <Route
    path="/"
    element={
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    }
    children={[
      <Route key="route:main" path="" element={<Navigate to="/classes" replace />} />,
      <Route
        key="route:classes:main"
        path="classes"
        children={[
          <Route key="route:classes:list" path="" element={<ClassesListPage />} />,
          <Route key="route:classes:details" path=":id" element={<ClassesDetailsPage />} />,
          <Route
            key="route:classes:workout"
            path=":clazzId/programs/:programId/workouts/:workoutId"
            element={<ClassesDetailsPage />}
          />,
        ]}
      />,
    ]}
  />,
];
