import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { Settings } from 'luxon';
import { getConfigs, GymDto, serviceOptions } from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  gyms: [] as GymDto[],
  currentGym: {} as GymDto,
  globalGym: {} as GymDto,
  totalNumberOfLocations: 0,
  locationStats: {} as any,
};

const slice = createSlice({
  name: 'gym',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getGymsSuccess(state, action) {
      state.gyms = action.payload.gyms;
    },
    getGymSuccess(state, action) {
      state.currentGym = action.payload;
    },
    setGlobalGymSuccess(state, action) {
      state.globalGym = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getGyms(
  params: {
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());

    const url = '/api/gyms';

    const configs = getConfigs('get', 'application/json', url, {});

    configs.params = params;
    const resp = await serviceOptions.axios!.request(configs);

    dispatch(
      slice.actions.getGymsSuccess({
        gyms: resp.data,
      })
    );
  };
}

export function setGlobalGym(gym: GymDto) {
  return async (dispatch: Dispatch) => {
    Settings.defaultZone = gym?.timeZone ?? 'system';
    dispatch(slice.actions.setGlobalGymSuccess(gym));
  };
}

// ----------------------------------------------------------------------
