/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { DateTime } from 'luxon';

export interface IRequestOptions extends AxiosRequestConfig {
  /** only in axios interceptor config*/
  loading?: boolean;
  showError?: boolean;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
  /** only in axios interceptor config*/
  loading: boolean;
  showError: boolean;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = {
    loading: serviceOptions.loading,
    showError: serviceOptions.showError,
    ...options,
    method,
    url,
  };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static findById(options: IRequestOptions = {}): Promise<AccountDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBookings(
    params: {
      /**  */
      id: number;
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      checkedIn?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/bookings';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { from: params['from'], to: params['to'], checkedIn: params['checkedIn'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTickets(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TicketDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/tickets';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ChangePasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/password';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logo(
    params: {
      /**  */
      id: number;
      /**  */
      files: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/profile-image';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setProfileImage(
    params: {
      /**  */
      accountId: number;
      /** requestBody */
      body?: FileDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{accountId}/profile-images';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMemberships(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/memberships';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeAccount(
    params: {
      /**  */
      accountId: number;
      /** requestBody */
      body?: AuthenticationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthorizationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{accountId}/sub-accounts';
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AccountSettingService {
  /**
   *
   */
  static getAccountSettings(options: IRequestOptions = {}): Promise<AccountSettingsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account-settings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: AccountSettingsDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountSettingsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account-settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class AuthenticationService {
  /**
   *
   */
  static authenticate(
    params: {
      /**  */
      tenantId?: number;
      /** requestBody */
      body?: AuthenticationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AuthorizationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/authentication';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { tenantId: params['tenantId'] };

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CalendarService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      calendarId: string;
      /**  */
      accessKey?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/calendars/{calendarId}';
      url = url.replace('{calendarId}', params['calendarId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { accessKey: params['accessKey'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ChainSearchesService {
  /**
   *
   */
  static search(
    params: {
      /**  */
      query?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChainDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chain-searches';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { query: params['query'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ChainService {
  /**
   *
   */
  static findById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChainDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chains/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSettings(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChainSettingsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chains/{id}/settings';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ChatsService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      search?: string;
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        search: params['search'],
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateChatDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      chatId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats/{chatId}';
      url = url.replace('{chatId}', params['chatId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      chatId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats/{chatId}';
      url = url.replace('{chatId}', params['chatId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFile(
    params: {
      /**  */
      chatId: string;
      /**  */
      messageId: string;
      /**  */
      fileId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats/{chatId}/messages/{messageId}/files/{fileId}';
      url = url.replace('{chatId}', params['chatId'] + '');
      url = url.replace('{messageId}', params['messageId'] + '');
      url = url.replace('{fileId}', params['fileId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditChatDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ChatDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/chats/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class CheckInService {
  /**
   *
   */
  static post(
    params: {
      /** requestBody */
      body?: CreateCheckinDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CheckInDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/CheckIn';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClassesService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      start?: string;
      /**  */
      fromDate?: string;
      /**  */
      toDate?: string;
      /**  */
      allowsGuestBooking?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClazzDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        start: params['start'],
        fromDate: params['fromDate'],
        toDate: params['toDate'],
        allowsGuestBooking: params['allowsGuestBooking'],
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      classId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClazzDetailDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes/{classId}';
      url = url.replace('{classId}', params['classId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getInstructors(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<InstructorDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes/{id}/instructors';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelClass(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Classes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClassFeedbackService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      classId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassFeedbackDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-feedback';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { classId: params['classId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateClassFeedbackDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassFeedbackDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-feedback';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ClassRegistrationService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      classHourId?: number;
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      checkedIn?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-registrations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        classHourId: params['classHourId'],
        from: params['from'],
        to: params['to'],
        checkedIn: params['checkedIn'],
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ParticipateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-registrations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-registrations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ParticipateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-registrations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createCheckIn(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateCheckinDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CheckInDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/class-registrations/{id}/check-in';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ContractsService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      contractId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contracts/{contractId}';
      url = url.replace('{contractId}', params['contractId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sign(
    params: {
      /**  */
      contractId: number;
      /** requestBody */
      body?: SignatureDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ContractDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/contracts/{contractId}/sign';
      url = url.replace('{contractId}', params['contractId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class DocumentService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      documentId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/documents/{documentId}';
      url = url.replace('{documentId}', params['documentId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<DocumentDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/documents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class DoorAccessService {
  /**
   *
   */
  static unlock(
    params: {
      /** requestBody */
      body?: UnlockDoorDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/door-access';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class EmployeesService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      searchString?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { searchString: params['searchString'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static details(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmployeeDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/employees/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class EventsService {
  /**
   *
   */
  static getAll(options: IRequestOptions = {}): Promise<EventDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EventDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createRegistration(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateRegistrationDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TicketDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/events/{id}/registrations';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ExerciseResultService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateExerciseResultDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExerciseResultDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/exercise-results';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getResults(
    params: {
      /**  */
      exerciseTypeIds?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExerciseResultDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/exercise-results';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { exerciseTypeIds: params['exerciseTypeIds'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ExerciseTypesService {
  /**
   *
   */
  static getExerciseTypes(options: IRequestOptions = {}): Promise<ExerciseTypeDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/exercise-types';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class FileService {
  /**
   *
   */
  static create(
    params: {
      /**  */
      files: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/files';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFileById(
    params: {
      /**  */
      path: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bookingboard-documents/{path}';
      url = url.replace('{path}', params['path'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getVideoFileById(
    params: {
      /**  */
      path: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bookingboard-video/{path}';
      url = url.replace('{path}', params['path'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAudioFileById(
    params: {
      /**  */
      path: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/bookingboard-audio/{path}';
      url = url.replace('{path}', params['path'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class GymsService {
  /**
   *
   */
  static index(options: IRequestOptions = {}): Promise<GymDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/gyms';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class LeaderboardsService {
  /**
   *
   */
  static get(
    params: {
      /**  */
      year?: number;
      /**  */
      month?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LeaderboardEntryDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/leaderboards';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { year: params['year'], month: params['month'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class MembersService {
  /**
   *
   */
  static findById(options: IRequestOptions = {}): Promise<AccountDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBookings(
    params: {
      /**  */
      id: number;
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      checkedIn?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/bookings';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { from: params['from'], to: params['to'], checkedIn: params['checkedIn'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ChangePasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/password';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logo(
    params: {
      /**  */
      id: number;
      /**  */
      files: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/profile-image';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMemberships(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{id}/memberships';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDocuments(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DocumentDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/documents';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContracts(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MembershipContractDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/contracts';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPaymentMethods(
    params: {
      /**  */
      memberId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentMethodDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/members/{memberId}/payment-methods';
      url = url.replace('{memberId}', params['memberId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class NewsService {
  /**
   *
   */
  static index(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/News';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { from: params['from'], to: params['to'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static findById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/News/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class OrdersService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<OrderDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      orderId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OrderDetailsDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders/{orderId}';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createReceipt(
    params: {
      /**  */
      orderId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/orders/{orderId}/receipts';
      url = url.replace('{orderId}', params['orderId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ParticipationsService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      classHourId?: number;
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      checkedIn?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Participations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        classHourId: params['classHourId'],
        from: params['from'],
        to: params['to'],
        checkedIn: params['checkedIn'],
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ParticipateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Participations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Participations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static edit(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: ParticipateDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassRegistrationDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Participations/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkIn(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: CreateCheckinDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CheckInDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Participations/{id}/check-in';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PasswordService {
  /**
   *
   */
  static createResetToken(
    params: {
      /** requestBody */
      body?: ResetPasswordDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/password-reset-token';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class PersonalTrainingsService {
  /**
   *
   */
  static findAll(
    params: {
      /**  */
      start?: string;
      /**  */
      end?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalTrainingDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-trainings';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { start: params['start'], end: params['end'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
      /**  */
      programId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PersonalTrainingDetailDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/personal-trainings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { programId: params['programId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProductsService {
  /**
   *
   */
  static getProducts(
    params: {
      /**  */
      pageNumber?: number;
      /**  */
      pageSize?: number;
      /**  */
      sortBy?: any | null[];
      /**  */
      direction?: Direction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        pageNumber: params['pageNumber'],
        pageSize: params['pageSize'],
        sortBy: params['sortBy'],
        direction: params['direction'],
      };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getProduct(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/products/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ProgramsService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<ProgramDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      programId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProgramDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/programs/{programId}';
      url = url.replace('{programId}', params['programId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class PurchaseService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreatePurchaseDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PurchaseDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/purchases';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class QuickLinksService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<QuickLinkDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/quick-links';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class ReferralService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<ReferralDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/referrals';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateReferralDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReferralDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/referrals';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class ShiftService {
  /**
   *
   */
  static findAll(options: IRequestOptions = {}): Promise<ShiftDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/shifts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateShiftDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/shifts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/shifts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: EditShiftDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/shifts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class TradableShiftService {
  /**
   *
   */
  static getTradableShifts(
    params: {
      /**  */
      shiftId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftSwapDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tradable-shifts';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { shiftId: params['shiftId'] };

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static markShiftAsTradable(
    params: {
      /** requestBody */
      body?: CreateShiftSwapDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftSwapDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tradable-shifts';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTradableShift(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftSwapDto[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tradable-shifts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static takeShift(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: UpdateShiftSwapDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftSwapDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tradable-shifts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeTradableShift(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ShiftSwapDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/tradable-shifts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  static getRoles(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}/roles';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      /** 适配ios13，get请求不允许带body */

      axios(configs, resolve, reject);
    });
  }
}

export class WorkoutResultService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateWorkoutResultDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<WorkoutResultDto> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Workout-results';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;

      axios(configs, resolve, reject);
    });
  }
}

export interface AccountDto {
  /**  */
  id?: number;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  profileImageUrl?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  ownerId?: string;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  activeMemberships?: MembershipDto[];

  /**  */
  settings?: AccountSettingsDto;

  /**  */
  subAccounts?: AccountDto[];

  /**  */
  primaryAccount?: AccountDto;

  /**  */
  creditBalanceAmount?: number;

  /**  */
  creditBalanceCurrency?: string;

  /**  */
  numberOfAttendances?: number;

  /**  */
  calendarId?: string;

  /**  */
  calendarAccessKey?: string;
}

export interface AccountSettingsDto {
  /**  */
  id?: number;

  /**  */
  accountId?: number;

  /**  */
  language?: string;

  /**  */
  isNewslettersAllowed?: boolean;

  /**  */
  isAnonymous?: boolean;

  /**  */
  syncCalendar?: boolean;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  activeAccount?: AccountDto;

  /**  */
  passcode?: string;
}

export interface AddressDto {
  /**  */
  id?: number;

  /**  */
  addressLine?: string;

  /**  */
  city?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  latitude?: number;

  /**  */
  longitude?: number;

  /**  */
  countryCode?: string;
}

export interface AuthenticationDto {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  chainId?: number;
}

export interface AuthorizationDto {
  /**  */
  token?: string;
}

export interface ChainDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  vatNo?: string;

  /**  */
  billingAddressId?: number;

  /**  */
  billingAddress?: AddressDto;

  /**  */
  logoUrl?: string;

  /**  */
  chainSettingsId?: number;

  /**  */
  domain?: string;

  /**  */
  domainOfPresentation?: string;

  /**  */
  subscriptionType?: SubscriptionType;
}

export interface ChainSettingsDto {
  /**  */
  subscription?: SubscriptionType;

  /**  */
  accessSystemProvider?: AccessSystemProviders;

  /**  */
  colorPrimary?: string;

  /**  */
  colorSecondary?: string;

  /**  */
  colorError?: string;

  /**  */
  colorInfo?: string;

  /**  */
  colorSuccess?: string;

  /**  */
  colorWarning?: string;
}

export interface ChangePasswordDto {
  /**  */
  memberId?: number;

  /**  */
  currentPassword?: string;

  /**  */
  newPassword?: string;
}

export interface ChatDto {
  /**  */
  id?: number;

  /**  */
  uid?: string;

  /**  */
  type?: string;

  /**  */
  name?: string;

  /**  */
  messages?: ChatMessageDto[];

  /**  */
  participants?: ChatParticipantDto[];

  /**  */
  createdDate?: DateTime;
}

export interface ChatMessageDto {
  /**  */
  id?: string;

  /**  */
  uid?: string;

  /**  */
  body?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  senderId?: number;

  /**  */
  contentType?: string;

  /**  */
  avatarUrl?: string;

  /**  */
  fullName?: string;

  /**  */
  chatId?: number;

  /**  */
  chatUid?: string;

  /**  */
  type?: MessageType;

  /**  */
  fileId?: number;

  /**  */
  file?: FileDto;

  /**  */
  hasSeenMessages?: HasSeenMessageDto[];
}

export interface ChatParticipantDto {
  /**  */
  id?: number;

  /**  */
  fullName?: string;

  /**  */
  role?: string;

  /**  */
  email?: string;

  /**  */
  avatarUrl?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  lastActivity?: DateTime;

  /**  */
  status?: string;
}

export interface CheckInDto {
  /**  */
  qrCode?: string;

  /**  */
  location?: LocationDto;

  /**  */
  createdAt?: DateTime;

  /**  */
  gymName?: string;

  /**  */
  classTitle?: string;

  /**  */
  classBegins?: DateTime;

  /**  */
  checkInStatus?: ParticipationStatus;

  /**  */
  checkInMessage?: string;
}

export interface ClassFeedbackDto {
  /**  */
  id?: number;

  /**  */
  body?: string;

  /**  */
  classId?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  rating?: number;

  /**  */
  overallRating?: number;

  /**  */
  coachPreparationRating?: number;

  /**  */
  coachObservationRating?: number;

  /**  */
  clarityOfInstructions?: number;

  /**  */
  logisticalRating?: number;

  /**  */
  feedbackHelpfulnessRating?: number;

  /**  */
  warmUpEffectivenessRating?: number;
}

export interface ClassRegistrationDto {
  /**  */
  id?: number;

  /**  */
  classId?: number;

  /**  */
  className?: string;

  /**  */
  classSubtitle?: string;

  /**  */
  classTint?: string;

  /**  */
  gym?: string;

  /**  */
  gymName?: string;

  /**  */
  gymEntity?: GymDto;

  /**  */
  status?: ParticipationStatus;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  createdOn?: DateTime;

  /**  */
  signedUpAt?: DateTime;

  /**  */
  participantDateOfBirth?: DateTime;

  /**  */
  closedForCheckInAt?: DateTime;

  /**  */
  participantName?: string;

  /**  */
  participantId?: number;

  /**  */
  participantProfileImageUrl?: string;

  /**  */
  isGuest?: boolean;

  /**  */
  isNewMember?: boolean;
}

export interface ClazzDetailDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  description?: string;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  closedForCheckInAt?: DateTime;

  /**  */
  gym?: string;

  gymEntity?: GymDto;

  /**  */
  subtitle?: string;

  /**  */
  tint?: string;

  /**  */
  seats?: number;

  /**  */
  numberOfParticipants?: number;

  /**  */
  isOpenGym?: boolean;

  /**  */
  closedClass?: boolean;

  /**  */
  maximumNumberOfParticipants?: number;

  /**  */
  numberOfParticipantsOnWaitingList?: number;

  /**  */
  imageUrl?: string;

  /**  */
  location?: string;

  /**  */
  program?: ProgramDto;

  /**  */
  instructors?: InstructorDto[];

  /**  */
  participants?: ClassRegistrationDto[];

  /**  */
  type?: string;
}

export interface ClazzDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  closedForCheckInAt?: DateTime;

  /**  */
  gym?: string;

  /**  */
  subtitle?: string;

  /**  */
  tint?: string;

  /**  */
  seats?: number;

  /**  */
  isOpenGym?: boolean;

  /**  */
  closedClass?: boolean;

  /**  */
  numberOfParticipants?: number;

  /**  */
  numberOfParticipantsOnWaitingList?: number;

  /**  */
  maximumNumberOfParticipants?: number;

  /**  */
  imageUrl?: string;

  /**  */
  program?: ProgramDto;

  /**  */
  instructors?: InstructorDto[];

  /**  */
  gymName?: string;

  /**  */
  gymEntity?: GymDto;

  /**  */
  location?: string;

  /**  */
  type?: string;
}

export interface ContractDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  body?: string;
}

export interface CreateChatDto {
  /**  */
  uid?: string;

  /**  */
  type?: string;

  /**  */
  name?: string;

  /**  */
  messages?: ChatMessageDto[];

  /**  */
  participants?: ChatParticipantDto[];
}

export interface CreateCheckinDto {
  /**  */
  qrCode?: string;

  /**  */
  location?: LocationDto;

  /**  */
  timestamp?: DateTime;

  /**  */
  accountId?: number;

  /**  */
  classId?: number;
}

export interface CreateClassFeedbackDto {
  /**  */
  body?: string;

  /**  */
  classId?: number;

  /**  */
  rating?: number;

  /**  */
  overallRating?: number;

  /**  */
  coachPreparationRating?: number;

  /**  */
  coachObservationRating?: number;

  /**  */
  clarityOfInstructions?: number;

  /**  */
  logisticalRating?: number;

  /**  */
  feedbackHelpfulnessRating?: number;

  /**  */
  warmUpEffectivenessRating?: number;
}

export interface CreateExerciseResultDto {
  /**  */
  exerciseTypeId?: number;

  /**  */
  exerciseId?: number;

  /**  */
  value?: number;

  /**  */
  count?: number;
}

export interface CreateProductPurchaseDto {
  /**  */
  productId?: number;

  /**  */
  quantity?: number;
}

export interface CreatePurchaseDto {
  /**  */
  products?: CreateProductPurchaseDto[];

  /**  */
  customerId?: number;
}

export interface CreateReferralDto {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  clazzId?: number;
}

export interface CreateRegistrationDto {
  /**  */
  ticketTypeId?: number;

  /**  */
  selectedOptions?: object;

  /**  */
  memberId?: number;
}

export interface CreateShiftDto {
  /**  */
  staffMemberId?: number;

  /**  */
  employeeId?: number;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  description?: string;

  /**  */
  numberOfRepetitions?: number;

  /**  */
  gymId?: number;
}

export interface CreateShiftSwapDto {
  /**  */
  shiftId?: number;
}

export interface CreateWorkoutResultDto {
  /**  */
  clazzId?: number;

  /**  */
  workoutId?: number;

  /**  */
  numberOfRounds?: number;

  /**  */
  count?: number;

  /**  */
  time?: string;

  /**  */
  scaling?: WorkoutScaling;
}

export interface DocumentDto {
  /**  */
  id?: number;

  /**  */
  fileOriginalName?: string;
}

export interface EditChatDto {
  /**  */
  id?: number;

  /**  */
  uid?: string;

  /**  */
  type?: string;

  /**  */
  name?: string;

  /**  */
  messages?: ChatMessageDto[];

  /**  */
  participants?: ChatParticipantDto[];
}

export interface EditShiftDto {
  /**  */
  id?: number;

  /**  */
  staffMemberId?: number;

  /**  */
  employeeId?: number;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  description?: string;

  /**  */
  numberOfRepetitions?: number;

  /**  */
  gymId?: number;
}

export interface EmployeeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  middleName?: string;

  /**  */
  profileImageUrl?: string;

  /**  */
  dateOfBirth?: DateTime;

  /**  */
  phoneNumber?: string;

  /**  */
  email?: string;

  /**  */
  title?: string;

  /**  */
  gender?: Gender;

  /**  */
  roles?: string[];

  /**  */
  profileText?: string;

  /**  */
  tint?: string;

  /**  */
  gymId?: number;

  /**  */
  calendarLink?: string;
}

export interface EventDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  description?: string;

  /**  */
  openForSignUpAt?: DateTime;

  /**  */
  closedForSignUpAt?: DateTime;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  timeZone?: string;

  /**  */
  backgroundImageUrl?: string;

  /**  */
  types?: TicketTypeDto[];

  /**  */
  options?: TicketOptionTypeDto[];
}

export interface ExerciseDto {
  /**  */
  id?: number;

  /**  */
  exerciseTypeId?: number;

  /**  */
  exerciseTypeName?: string;

  /**  */
  relationTo?: string;

  /**  */
  count?: number;

  /**  */
  mensWeight?: number;

  /**  */
  womensWeight?: number;

  /**  */
  percentage?: number;

  /**  */
  unit?: string;

  /**  */
  workoutId?: number;

  /**  */
  workoutName?: string;
}

export interface ExerciseResultDto {
  /**  */
  id?: number;

  /**  */
  exerciseTypeName?: string;

  /**  */
  exerciseRelationTo?: string;

  /**  */
  exercisePercentage?: number;

  /**  */
  exerciseId?: number;

  /**  */
  exerciseTypeId?: number;

  /**  */
  value?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  ownerId?: number;
}

export interface ExerciseTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  abbreviation?: string;

  /**  */
  description?: string;
}

export interface FileDto {
  /**  */
  id?: number;

  /**  */
  fileName?: string;

  /**  */
  data?: string;

  /**  */
  fileExt?: string;

  /**  */
  url?: string;

  /**  */
  publicUrl?: string;

  /**  */
  size?: number;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  contentType?: string;
}

export interface GymDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  address?: AddressDto;

  /**  */
  logoUrl?: string;

  /**  */
  timeZone?: string;

  /**  */
  description?: string;

  /**  */
  shortDescription?: string;

  /**  */
  images?: FileDto[];

  /**  */
  openingHours?: OpeningHourDto[];

  /**  */
  externalId?: string;

  /**  */
  checkInQrCode?: string;

  /**  */
  locks?: LockDto[];
}

export interface HasSeenMessageDto {
  /**  */
  messageId?: string;

  /**  */
  messageUid?: string;

  /**  */
  seenAt?: DateTime;

  /**  */
  accountId?: number;

  /**  */
  chatId?: number;

  /**  */
  chatUid?: string;
}

export interface InstructorDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  title?: string;

  /**  */
  tint?: string;

  /**  */
  profileImageUrl?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  dateOfBirth?: DateTime;
}

export interface LeaderboardEntryDto {
  /**  */
  participationLeaderboard?: LeaderboardParticipationEntryDto[];

  /**  */
  trainingLeaderboard?: LeaderboardParticipationEntryDto[];

  /**  */
  workoutLeaderboard?: LeaderboardWorkoutEntryDto[];
}

export interface LeaderboardParticipationEntryDto {
  /**  */
  rank?: number;

  /**  */
  memberName?: string;

  /**  */
  memberProfileImageUrl?: string;

  /**  */
  numberOfParticipations?: number;
}

export interface LeaderboardWorkoutEntryDto {
  /**  */
  rank?: number;

  /**  */
  memberName?: string;

  /**  */
  memberProfileImageUrl?: string;

  /**  */
  numberOfPoints?: number;

  /**  */
  scaling?: WorkoutScaling;
}

export interface LocationDto {
  /**  */
  longitude?: number;

  /**  */
  latitude?: number;
}

export interface LockDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  address?: string;

  /**  */
  externalId?: string;

  /**  */
  openingHours?: OpeningHourDto[];

  /**  */
  useAutoLock?: boolean;

  /**  */
  automaticallyLockDoorsOutsideOpeningHours?: boolean;

  /**  */
  automaticallyUnlockDoorsInsideOpeningHours?: boolean;
}

export interface MembershipContractDto {
  /**  */
  contractId?: number;

  /**  */
  contractName?: string;

  /**  */
  membershipId?: number;

  /**  */
  membershipName?: string;

  /**  */
  missingSignature?: boolean;
}

export interface MembershipDto {
  /**  */
  id?: number;

  /**  */
  chain?: string;

  /**  */
  name?: string;

  /**  */
  logoUrl?: string;

  /**  */
  voucherClipsLeft?: number;

  /**  */
  renewalDate?: DateTime;

  /**  */
  validFrom?: DateTime;

  /**  */
  validTo?: DateTime;

  /**  */
  gym?: GymDto;
}

export interface NewsDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  subtitle?: string;

  /**  */
  body?: string;

  /**  */
  teaser?: string;

  /**  */
  imageUrl?: string;

  /**  */
  publishedOn?: DateTime;

  /**  */
  chainName?: string;
}

export interface OpeningHourDto {
  /**  */
  from?: string;

  /**  */
  to?: string;

  /**  */
  dayOfWeek?: DayOfWeek;

  /**  */
  externalId?: string;
}

export interface OrderDetailsDto {
  /**  */
  id?: number;

  /**  */
  customerName?: string;

  /**  */
  customerId?: number;

  /**  */
  customerProfileImageUrl?: string;

  /**  */
  customerFirstName?: string;

  /**  */
  customerLastName?: string;

  /**  */
  customerEmailAddress?: string;

  /**  */
  customerPhoneNumber?: string;

  /**  */
  customerExternalLink?: string;

  /**  */
  vatNo?: string;

  /**  */
  status?: OrderStatuses;

  /**  */
  companyName?: string;

  /**  */
  city?: string;

  /**  */
  addressLine?: string;

  /**  */
  postalNumber?: string;

  /**  */
  country?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  comment?: string;

  /**  */
  items?: OrderItemDto[];

  /**  */
  discount?: number;

  /**  */
  isPaid?: boolean;

  /**  */
  orderNumber?: string;

  /**  */
  debtCollectionLink?: string;

  /**  */
  invoiceId?: number;
}

export interface OrderDto {
  /**  */
  id?: number;

  /**  */
  customerName?: string;

  /**  */
  customerId?: number;

  /**  */
  customerProfileImageUrl?: string;

  /**  */
  orderNumber?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  status?: OrderStatuses;

  /**  */
  totalAmount?: number;

  /**  */
  totalCurrency?: string;

  /**  */
  invoiceId?: number;
}

export interface OrderItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  quantity?: number;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  discount?: number;

  /**  */
  taxRate?: TaxRateDto;

  /**  */
  description?: string;

  /**  */
  order?: OrderDto;
}

export interface ParticipateDto {
  /**  */
  id?: number;

  /**  */
  classHourId?: number;

  /**  */
  accountId?: number;

  /**  */
  status?: ParticipationStatus;
}

export interface PaymentMethodDto {
  /**  */
  id?: number;

  /**  */
  state?: string;

  /**  */
  customer?: string;

  /**  */
  created?: DateTime;

  /**  */
  cardType?: string;

  /**  */
  expDate?: string;

  /**  */
  maskedCard?: string;

  /**  */
  cardCountry?: string;

  /**  */
  primary?: boolean;

  /**  */
  gateway?: ExternalSystemProviders;

  /**  */
  externalId?: string;
}

export interface PersonalTrainingDetailDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  description?: string;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  closedForCheckInAt?: DateTime;

  /**  */
  gym?: string;

  /**  */
  subtitle?: string;

  /**  */
  tint?: string;

  /**  */
  seats?: number;

  /**  */
  numberOfParticipants?: number;

  /**  */
  isOpenGym?: boolean;

  /**  */
  closedClass?: boolean;

  /**  */
  maximumNumberOfParticipants?: number;

  /**  */
  numberOfParticipantsOnWaitingList?: number;

  /**  */
  imageUrl?: string;

  /**  */
  program?: ProgramDto;

  /**  */
  instructors?: InstructorDto[];

  /**  */
  participants?: ClassRegistrationDto[];
}

export interface PersonalTrainingDto {
  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  closedForCheckInAt?: DateTime;

  /**  */
  gym?: string;

  /**  */
  subtitle?: string;

  /**  */
  tint?: string;

  /**  */
  seats?: number;

  /**  */
  isOpenGym?: boolean;

  /**  */
  closedClass?: boolean;

  /**  */
  numberOfParticipants?: number;

  /**  */
  numberOfParticipantsOnWaitingList?: number;

  /**  */
  maximumNumberOfParticipants?: number;

  /**  */
  imageUrl?: string;

  /**  */
  program?: ProgramDto;

  /**  */
  instructors?: InstructorDto[];
}

export interface ProductDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  description?: string;

  /**  */
  quantity?: number;

  /**  */
  taxRateId?: number;

  /**  */
  taxRate?: number;

  /**  */
  taxRateInclusive?: boolean;

  /**  */
  priceAmount?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  categoryId?: number;

  /**  */
  categoryName?: string;

  /**  */
  chainId?: number;

  /**  */
  imageUrl?: string;

  /**  */
  rank?: number;

  /**  */
  memberRank?: number;

  /**  */
  ordering?: number;
}

export interface ProgramDto {
  /**  */
  name?: string;

  /**  */
  classId?: number;

  /**  */
  workouts?: WorkoutDto[];

  /**  */
  chainId?: number;

  /**  */
  date?: DateTime;

  /**  */
  createdDate?: DateTime;

  /**  */
  lastModifiedDate?: DateTime;

  /**  */
  coachesNotes?: string;
}

export interface PurchaseDto {
  /**  */
  productId?: number;
}

export interface QuickLinkDto {
  /**  */
  id?: number;

  /**  */
  url?: string;

  /**  */
  name?: string;

  /**  */
  gym?: GymDto;
}

export interface ReferralDto {
  /**  */
  firstName?: string;

  /**  */
  lastName?: string;

  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  status?: LeadStatuses;
}

export interface ResetPasswordDto {
  /**  */
  email?: string;

  /**  */
  phoneNumber?: string;
}

export interface ShiftDto {
  /**  */
  id?: number;

  /**  */
  staffMemberId?: number;

  /**  */
  staffMemberName?: string;

  /**  */
  staffMemberTint?: string;

  /**  */
  classId?: number;

  /**  */
  className?: string;

  /**  */
  classTint?: string;

  /**  */
  gymName?: string;

  /**  */
  where?: string;

  /**  */
  staffMemberProfileImageUrl?: string;

  /**  */
  begins?: DateTime;

  /**  */
  ends?: DateTime;

  /**  */
  description?: string;

  /**  */
  swaps?: ShiftSwapDto[];
}

export interface ShiftSwapDto {
  /**  */
  id?: number;

  /**  */
  employeeId?: number;

  /**  */
  employeeName?: string;

  /**  */
  employeeTint?: string;

  /**  */
  employeeProfileImageUrl?: string;

  /**  */
  shiftId?: number;

  /**  */
  shiftEnds?: DateTime;

  /**  */
  shiftBegins?: DateTime;

  /**  */
  createdAt?: DateTime;

  /**  */
  description?: string;

  /**  */
  classId?: number;

  /**  */
  className?: string;

  /**  */
  classTint?: string;

  /**  */
  gymName?: string;

  /**  */
  where?: string;

  /**  */
  location?: string;
}

export interface SignatureDto {
  /**  */
  membershipId?: number;

  /**  */
  value?: string;
}

export interface TaxRateDto {
  /**  */
  value?: number;

  /**  */
  inclusive?: boolean;
}

export interface TicketDto {
  /**  */
  id?: number;

  /**  */
  eventId?: number;
}

export interface TicketOptionTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  values?: string[];

  /**  */
  type?: OptionTypeTypes;
}

export interface TicketTypeDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  numberOfAvailableTickets?: number;

  /**  */
  numberOfRemainingTickets?: number;

  /**  */
  priceCurrency?: string;

  /**  */
  priceAmount?: number;

  /**  */
  options?: TicketOptionTypeDto[];

  /**  */
  taxRate?: number;

  /**  */
  hasTicketsLeft?: boolean;
}

export interface UnlockDoorDto {
  /**  */
  serialNumber?: string;

  /**  */
  location?: LocationDto;

  /**  */
  timestamp?: DateTime;
}

export interface UpdateShiftSwapDto {
  /**  */
  id?: number;

  /**  */
  takenById?: number;

  /**  */
  shiftId?: number;
}

export interface WorkoutDto {
  /**  */
  isFreeText?: boolean;

  /**  */
  id?: number;

  /**  */
  title?: string;

  /**  */
  description?: string;

  /**  */
  comment?: string;

  /**  */
  timecap?: string;

  /**  */
  workoutTypeId?: number;

  /**  */
  workoutTypeName?: string;

  /**  */
  workoutTypeAbbreviation?: string;

  /**  */
  exercises?: ExerciseDto[];

  /**  */
  results?: WorkoutResultDto[];
}

export interface WorkoutResultDto {
  /**  */
  id?: number;

  /**  */
  clazzId?: number;

  /**  */
  workoutId?: number;

  /**  */
  ownerId?: number;

  /**  */
  numberOfRounds?: number;

  /**  */
  count?: number;

  /**  */
  time?: string;

  /**  */
  createdDate?: DateTime;

  /**  */
  scaling?: WorkoutScaling;
}

export enum AccessSystemProviders {
  'OutScale' = 'OutScale',
  'Exivo' = 'Exivo',
  'JustFace' = 'JustFace',
  'Danalock' = 'Danalock',
}

export enum DayOfWeek {
  'Sunday' = 'Sunday',
  'Monday' = 'Monday',
  'Tuesday' = 'Tuesday',
  'Wednesday' = 'Wednesday',
  'Thursday' = 'Thursday',
  'Friday' = 'Friday',
  'Saturday' = 'Saturday',
}

export enum Direction {
  'Asc' = 'Asc',
  'Desc' = 'Desc',
}

export enum ExternalSystemProviders {
  'Reepay' = 'Reepay',
  'Stripe' = 'Stripe',
  'Dibs' = 'Dibs',
  'Danalock' = 'Danalock',
  'Nets' = 'Nets',
  'Clearhaus' = 'Clearhaus',
  'Collectia' = 'Collectia',
}

export enum Gender {
  'Male' = 'Male',
  'Female' = 'Female',
  'Unspecified' = 'Unspecified',
}

export enum LeadStatuses {
  'Won' = 'Won',
  'Lost' = 'Lost',
  'Potential' = 'Potential',
}

export enum MessageType {
  'Audio' = 'Audio',
  'Custom' = 'Custom',
  'File' = 'File',
  'Image' = 'Image',
  'System' = 'System',
  'Text' = 'Text',
  'Unsupported' = 'Unsupported',
  'Video' = 'Video',
}

export enum OptionTypeTypes {
  'Radio' = 'Radio',
  'Checkbox' = 'Checkbox',
  'Select' = 'Select',
  'Text' = 'Text',
}

export enum OrderStatuses {
  'Open' = 'Open',
  'Paid' = 'Paid',
  'PendingPayment' = 'PendingPayment',
  'Closed' = 'Closed',
  'Cancelled' = 'Cancelled',
  'MissingPayment' = 'MissingPayment',
  'SentToInkasso' = 'SentToInkasso',
  'Refunded' = 'Refunded',
}

export enum ParticipationStatus {
  'OnTime' = 'OnTime',
  'TooLate' = 'TooLate',
  'OptedOut' = 'OptedOut',
  'OptedOutTooLate' = 'OptedOutTooLate',
  'OnWaitingList' = 'OnWaitingList',
  'SignedUp' = 'SignedUp',
  'NeverMet' = 'NeverMet',
}

export enum SubscriptionType {
  'Standard' = 'Standard',
  'Premium' = 'Premium',
  'Platinum' = 'Platinum',
}

export enum WorkoutScaling {
  'Rx' = 'Rx',
  'Scaled' = 'Scaled',
}
