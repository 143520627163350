import { useEffect, useMemo } from 'react';
// @mui
import Container from '@mui/material/Container';
import { Button, Card, Stack, Typography } from '@mui/material';
import Markdown from 'src/components/markdown';
// components
import Iconify from 'src/components/iconify';
//
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { useParams, useRouter } from '../../../routes/hook';
import { getClass } from '../../../redux/slices/classes';

// ----------------------------------------------------------------------

export default function ClassesDetailsView() {
  const params = useParams();

  const { t } = useLocales();

  const { clazzId = '', workoutId = '' } = params;

  const clazz = useSelector((state) => state.clazz.currentClass);

  const dispatch = useDispatch();

  const router = useRouter();

  useEffect(() => {
    dispatch(getClass(parseInt(clazzId, 10)));
  }, [dispatch, clazzId]);

  const workout = useMemo(() => {
    const id = parseInt(workoutId, 10);
    return clazz.program?.workouts?.find((el) => el.id === id);
  }, [clazz, workoutId]);

  return (
    <Container maxWidth={false}>
      <Button
        sx={{
          mb: 2,
        }}
        onClick={() => router.back()}
        startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
      >
        {t('Back')}
      </Button>

      <Stack spacing={3}>
        <Card key={`workout-${workout?.id}`}>
          <Stack
            spacing={2}
            sx={{
              p: 3,
              position: 'relative',
            }}
          >
            <Typography variant="h4">{workout?.title ?? `Workout #1`}</Typography>
            <Markdown children={workout?.description ?? ''} />
          </Stack>
        </Card>
      </Stack>
    </Container>
  );
}
