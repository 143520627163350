// utils
import { paramCase } from 'src/utils/change-case';
import { _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/',
};

// ----------------------------------------------------------------------

export const paths = {
  refresh: '/blank',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  classes: {
    root: '/classes',
    new: '/classes/new',
    edit: (id: number) => `/classes/${id}/edit`,
    view: (clazzId: number, programId: number, workoutId: number) =>
      `/classes/${clazzId}/programs/${programId}/workouts/${workoutId}`,
  },
};
