import { Navigate, Route } from 'react-router-dom';
import { ApmRoutes } from '@elastic/apm-rum-react';
// config
import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = [
    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // No match 404
    <Route path="*" element={<Navigate to="/404" replace />} key="notfound" />,
  ];

  return <ApmRoutes>{routes}</ApmRoutes>;
}
