//
import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import {
  Avatar,
  Box,
  Chip,
  Container,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Typography,
} from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
// theme
// components
import { DateTime, Duration } from 'luxon';
import Iconify from 'src/components/iconify';
// types
import { StyledItem } from '../../../components/nav-section/horizontal/styles';
//
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getClass, getClasses } from '../../../redux/slices/classes';
import { ClazzDto } from '../../../api';
import ClassParticipants from '../class-participants';
import ClassProgram from '../class-program';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function ClassesListView() {
  const theme = useTheme();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const [selectedClazzId, setSelectedClazzId] = useState(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const clazzId = queryParams.get('clazzId');

    return clazzId ? parseInt(clazzId, 10) : null;
  });

  const classes = useSelector((state) => state.clazz.classes);

  const filteredClasses = useMemo(() => {
    const filtered = classes.filter((el) => el.isOpenGym !== true && el.closedClass !== true);
    filtered.sort((a: ClazzDto, b: ClazzDto) => a.begins!.toMillis() - b.begins!.toMillis());
    return filtered;
  }, [classes]);

  const [timestamp, setTimestamp] = useState(DateTime.now());

  useMemo(
    () =>
      setInterval(() => {
        setTimestamp(DateTime.now());
      }, 15 * 1000),
    []
  );

  const selectedClazz = useSelector((state) => state.clazz.currentClass);

  const timeToClass = useMemo(() => {
    if (selectedClazz?.begins) {
      const duration = selectedClazz.begins!.diff(timestamp.startOf('minute')).rescale();
      if (duration < Duration.fromMillis(0)) {
        return '';
      }

      return duration.toHuman({ listStyle: 'long' });
    }
    return '';
  }, [timestamp, selectedClazz]);

  const [currentTab, setCurrentTab] = useState(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tab = queryParams.get('currentTab');

    return tab ?? 'participants';
  });

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const TABS = [
    {
      value: 'participants',
      label: t('Participants'),
      icon: <Iconify icon="clarity:users-line" width={24} />,
    },
    {
      value: 'program',
      label: t('Program'),
      icon: <Iconify icon="fluent:dumbbell-16-regular" width={24} />,
    },
  ];

  useEffect(() => {
    dispatch(
      getClasses({
        fromDate: timestamp.startOf('day'),
        toDate: timestamp.endOf('day'),
      })
    );
  }, [dispatch, timestamp]);

  useEffect(() => {
    if (selectedClazzId) {
      dispatch(getClass(selectedClazzId));
    } else if (filteredClasses.length > 0) {
      dispatch(getClass(filteredClasses[0].id!));
    }
  }, [filteredClasses, selectedClazzId, dispatch]);

  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?clazzId=${selectedClazz?.id ?? ''}&currentTab=${currentTab ?? ''}`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [selectedClazz, currentTab]);

  const changeClazz = (clazz: ClazzDto) => {
    setSelectedClazzId(clazz.id!);
  };

  const renderEventContent = (clazz: ClazzDto) => (
    <StyledItem
      onClick={() => changeClazz(clazz)}
      disableGutters
      depth={1}
      active={selectedClazz.id === clazz.id}
      disabled={false}
      config={{
        itemGap: 4,
        iconSize: 24,
        itemRootHeight: 64,
        itemSubHeight: 36,
        itemPadding: '4px 8px 4px 12px',
        itemRadius: 8,
        hiddenLabel: false,
      }}
    >
      <Avatar
        src={clazz.imageUrl}
        alt={clazz.title}
        sx={{
          mx: 'auto',
          width: 44,
          height: 44,
          border: `solid 2px ${theme.palette.common.white}`,
        }}
      />
      <ListItem disablePadding>
        <ListItemText
          sx={{
            ml: 1,
          }}
          primary={clazz.title}
          secondary={`${clazz.begins?.toFormat('HH:mm')} - ${clazz.ends?.toFormat('HH:mm')}`}
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            textTransform: 'capitalize',
            fontWeight: selectedClazz.id === clazz.id ? 'fontWeightBold' : 'fontWeightMedium',
          }}
        />
        <ListItemIcon>
          {clazz.ends! < timestamp && <Chip size="small" label={t('Held')} />}
        </ListItemIcon>
      </ListItem>
    </StyledItem>
  );

  return (
    <Container maxWidth={false}>
      <Grid
        container
        spacing={3}
        justifyContent={{
          xs: 'center',
          md: 'space-between',
        }}
      >
        <Grid xs={12} md={4} lg={3} xl={2}>
          <Typography variant="h3" sx={{ mb: 3 }}>
            {timestamp.toLocaleString(DateTime.DATE_MED)}
          </Typography>
          <Stack spacing={0.5}>{filteredClasses.map((el) => renderEventContent(el))}</Stack>
        </Grid>
        <Grid xs={12} md={8} lg={9} xl={10}>
          <Box
            sx={{
              mb: 3,
              height: 80,
              position: 'relative',
            }}
          >
            <ListItemText
              sx={{
                textAlign: { xs: 'center', md: 'unset' },
              }}
              primary={selectedClazz.title}
              secondary={selectedClazz.subtitle}
              primaryTypographyProps={{
                typography: 'h4',
              }}
              secondaryTypographyProps={{
                mt: 0.5,
                color: 'inherit',
                component: 'span',
                typography: 'body2',
                sx: { opacity: 0.48 },
              }}
            />

            {timeToClass && (
              <Chip
                sx={{
                  top: 8,
                  right: 0,
                  position: 'absolute',
                }}
                icon={<Iconify icon="mdi:clock-outline" />}
                size="small"
                color="info"
                label={timeToClass}
              />
            )}

            <Tabs
              value={currentTab}
              onChange={handleChangeTab}
              sx={{
                bottom: 0,
                right: 0,
                position: 'absolute',
                bgcolor: 'background.paper',
                [`& .${tabsClasses.flexContainer}`]: {
                  pr: { md: 0 },
                  justifyContent: {
                    sm: 'center',
                    md: 'flex-end',
                  },
                },
              }}
            >
              {TABS.map((tab) => (
                <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
              ))}
            </Tabs>
          </Box>

          {currentTab === 'participants' && (
            <ClassParticipants
              clazz={selectedClazz}
              participants={selectedClazz.participants ?? []}
            />
          )}

          {currentTab === 'program' && (
            <ClassProgram program={selectedClazz.program} clazz={selectedClazz} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
