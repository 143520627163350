import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import chainReducer from './slices/chain';
import gymReducer from './slices/gym';
import classesReducer from './slices/classes';

// ----------------------------------------------------------------------

const chainPersistConfig = {
  key: 'chain',
  storage,
  keyPrefix: 'redux-',
};

const gymPersistConfig = {
  key: 'gym',
  storage,
  keyPrefix: 'redux-',
};

export const rootReducer = combineReducers({
  chain: persistReducer(chainPersistConfig, chainReducer),
  gym: persistReducer(gymPersistConfig, gymReducer),
  clazz: classesReducer,
});
