import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));

// ----------------------------------------------------------------------

const authJwt = (
  <Route
    key="route:jwt"
    path="jwt"
    element={
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    }
    children={[
      <Route
        key="route:jwt:login"
        path="login"
        element={
          <AuthClassicLayout>
            <JwtLoginPage />
          </AuthClassicLayout>
        }
      />,
    ]}
  />
);

export const authRoutes = [<Route path="auth" children={authJwt} />];
