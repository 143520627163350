import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import { Button, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
// components
import Markdown from '../../components/markdown';
import { ClazzDto, ProgramDto } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  program?: ProgramDto;
  clazz: ClazzDto;
};

export default function ClassProgram({ program, clazz }: Props) {
  const { t } = useLocales();

  const router = useRouter();

  return (
    <Stack spacing={3}>
      {program?.workouts?.map((w) => (
        <Card key={`workout-${w.id}`}>
          <CardHeader
            disableTypography
            title={<Typography variant="h6">{w.title}</Typography>}
            subheader={<Typography variant="subtitle1">{w.workoutTypeAbbreviation}</Typography>}
            action={
              <Button
                variant="contained"
                onClick={() => router.push(paths.classes.view(clazz.id!, 1, w.id!))}
                startIcon={<Iconify icon="solar:full-screen-square-linear" />}
              >
                {t('Show')}
              </Button>
            }
          />
          <Stack
            spacing={2}
            sx={{
              p: 3,
              position: 'relative',
            }}
          >
            <Markdown children={w.description ?? ''} />
          </Stack>
        </Card>
      ))}
    </Stack>
  );
}
