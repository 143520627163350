import { useMemo } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Unstable_Grid2';
import { AvatarShape } from 'src/assets/illustrations';
import { Divider } from '@mui/material';
// types
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { ClassRegistrationDto, ClazzDto, ParticipationStatus } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  participants: ClassRegistrationDto[];
  clazz: ClazzDto;
};

export default function ClassParticipants({ participants, clazz }: Props) {
  const filteredParticipants = useMemo(
    () =>
      participants.filter(
        (x) =>
          x.status !== ParticipationStatus.OptedOut &&
          x.status !== ParticipationStatus.OptedOutTooLate
      ),
    [participants]
  );

  const gridColums = useMemo(() => {
    const n = Math.ceil((clazz.maximumNumberOfParticipants ?? filteredParticipants.length) / 3);
    if (n <= 2) {
      return 4;
    }
    return n;
  }, [filteredParticipants, clazz]);

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <Stack spacing={3}>
          <Box
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(4, 1fr)',
              xl: `repeat(${gridColums}, 1fr)`,
            }}
          >
            {filteredParticipants.map((p) => (
              <ParticipantItem key={p.id} participant={p} clazz={clazz} />
            ))}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}

// ----------------------------------------------------------------------

type ParticipantItemProps = {
  participant: ClassRegistrationDto;
  clazz: ClazzDto;
};

function ParticipantItem({ participant, clazz }: ParticipantItemProps) {
  const { participantName, participantProfileImageUrl, status } = participant;

  const { t } = useLocales();

  const theme = useTheme();

  return (
    <Card sx={{ textAlign: 'center' }}>
      <Box sx={{ position: 'relative' }}>
        <AvatarShape
          sx={{
            left: 0,
            right: 0,
            zIndex: 10,
            mx: 'auto',
            bottom: -26,
            position: 'absolute',
          }}
        />

        <Box
          sx={{
            right: 8,
            zIndex: 14,
            top: -4,
            position: 'absolute',
          }}
        >
          {status === ParticipationStatus.NeverMet && (
            <Chip
              size="small"
              color="error"
              label={t(`${status}`)}
              sx={{
                mt: 1.5,
              }}
            />
          )}

          {status === ParticipationStatus.OnWaitingList && (
            <Chip
              size="small"
              color="warning"
              label={t(`${status}`)}
              sx={{
                mt: 1.5,
              }}
            />
          )}

          {status === ParticipationStatus.TooLate && (
            <Chip
              size="small"
              color="warning"
              label={t(`${status}`)}
              sx={{
                mt: 1.5,
              }}
            />
          )}

          {status === ParticipationStatus.OnTime && (
            <Chip
              size="small"
              color="success"
              label={t(`${status}`)}
              sx={{
                mt: 1.5,
              }}
            />
          )}

          {status !== ParticipationStatus.NeverMet &&
            status !== ParticipationStatus.TooLate &&
            status !== ParticipationStatus.OnWaitingList &&
            status !== ParticipationStatus.OnTime && (
              <Chip
                size="small"
                label={t(`${status}`)}
                sx={{
                  mt: 1.5,
                }}
              />
            )}
          {participant.isGuest && (
            <Chip
              size="small"
              color="info"
              label={t(`Guest`)}
              sx={{
                mt: 1.5,
              }}
              icon={<Iconify icon="fluent:guest-16-filled" />}
            />
          )}
        </Box>

        <Avatar
          alt={participantName}
          src={participantProfileImageUrl}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            left: 0,
            right: 0,
            bottom: -32,
            mx: 'auto',
            position: 'absolute',
          }}
        />

        <Image
          src={participantProfileImageUrl ?? clazz.imageUrl ?? ''}
          alt={participantName ?? ''}
          ratio="16/9"
          overlay={alpha(theme.palette.grey[900], 0.48)}
        />
      </Box>
      <ListItemText
        sx={{ mt: 7, mb: 1 }}
        primary={participantName}
        primaryTypographyProps={{ typography: 'subtitle1' }}
        secondaryTypographyProps={{ component: 'span', mt: 0.5 }}
      />

      <Divider sx={{ borderStyle: 'dashed' }} />
    </Card>
  );
}
