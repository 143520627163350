import { useEffect } from 'react';
import { m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import ListItemText from '@mui/material/ListItemText';
// locales
// components
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useDispatch, useSelector } from '../../redux/store';
import { getGyms, setGlobalGym } from '../../redux/slices/gym';
import { varHover } from '../../components/animate';
import { updateSelectedGym } from '../../utils/axios';

// ----------------------------------------------------------------------

export default function GymSelector() {
  const popover = usePopover();

  const dispatch = useDispatch();

  const selectedGym = useSelector((state) => state.gym.globalGym);

  const gyms = useSelector((state) => state.gym.gyms);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getGyms());
  }, [dispatch]);

  useEffect(() => {
    if (gyms.length) {
      const gymExists = gyms.find((el) => el.id === selectedGym.id) !== undefined;
      if (!gymExists) {
        dispatch(setGlobalGym(gyms[0]));
        updateSelectedGym(gyms[0]);
      }
    }
  }, [selectedGym, gyms, dispatch]);

  const handleGymChange = (id: number | undefined) => {
    const newGym = gyms.find((el) => el.id === id);
    dispatch(setGlobalGym(newGym!));
    updateSelectedGym(newGym);

    // Refresh page without doing full reload
    navigate(0);

    popover.onClose();
  };

  return (
    <>
      {gyms.length && (
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          onClick={popover.onOpen}
          sx={{
            width: 40,
            height: 40,
            background: (theme) => alpha(theme.palette.grey[500], 0.08),
            ...(popover.open && {
              background: (theme) =>
                `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
            }),
          }}
        >
          <Avatar
            src={selectedGym?.logoUrl}
            alt={selectedGym?.name}
            sx={{
              width: 36,
              height: 36,
              border: (theme) => `solid 2px ${theme.palette.background.default}`,
            }}
          />
        </IconButton>
      )}

      <CustomPopover open={popover.open} onClose={popover.onClose}>
        {gyms.map((option) => (
          <MenuItem
            key={`gyms-selector-${option.id}`}
            selected={option.id === selectedGym.id}
            onClick={() => handleGymChange(option.id)}
          >
            <Badge anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} sx={{ mr: 2 }}>
              <Avatar alt={option.name} src={option.logoUrl} />
            </Badge>

            <ListItemText
              primary={option.name}
              primaryTypographyProps={{ typography: 'subtitle2' }}
            />
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
