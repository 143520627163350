import { init as initApm } from '@elastic/apm-rum';

type Props = {
  children: React.ReactNode;
};

export default function Apm({ children }: Props) {
  if (process.env.REACT_APP_APM_ENVIRONMENT === 'Production') {
    initApm({
      serviceName: 'BookingBoard_Web_Dashboard',

      serverUrl: 'https://bf89b94ed1fd444588cea4e2f6fd83fd.apm.europe-west3.gcp.cloud.es.io:443',

      serviceVersion: '',

      environment: 'Production',
    });
  }

  return <>{children}</>;
}
