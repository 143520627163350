import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import { ClassesService, ClazzDetailDto, ClazzDto, getConfigs, serviceOptions } from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  classes: [] as ClazzDto[],
  totalNumberOfClasses: 0,
  totalNumberOfClassRegistrations: 0,
  currentClass: {} as ClazzDetailDto,
};

const slice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClassesSuccess(state, action) {
      state.classes = action.payload.classes;
      state.totalNumberOfClasses = action.payload.totalNumberOfClasses;
    },
    getClassSuccess(state, action) {
      state.currentClass = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getClasses(
  params: {
    fromDate?: DateTime | null;
    toDate?: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const url = '/api/classes';

    const configs = getConfigs('get', 'application/json', url, {});

    configs.params = params;
    const resp = await serviceOptions.axios!.request(configs);

    dispatch(
      slice.actions.getClassesSuccess({
        totalNumberOfClasses: parseInt(resp.headers['x-total-count'], 10),
        classes: resp.data,
      })
    );
    dispatch(slice.actions.stopLoading());
  };
}

export function getClass(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    const clazz = await ClassesService.getById({ classId: id });

    dispatch(slice.actions.getClassSuccess(clazz));

    dispatch(slice.actions.stopLoading());
  };
}
